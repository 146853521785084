.squares {
  /* keep it in the background and spill offscreen*/
  position: fixed;
  bottom: -256px;
  left: -256px;
  right: -256px;
  top: -256px;
  z-index: -1;

  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  &.green {
    background-image: url("../images/squares-green-psx-2x.png");
  }

  &.blue {
    background-image: url("../images/squares-blue-psx-2x.png");
  }

  &.grey {
    background-image: url("../images/squares-grey-psx-2x.png");
  }

  &.brown {
    background-image: url("../images/squares-brown-psx-2x.png");
  }

  &.scroll-se {
    animation-name: scroll-down-right;
  }

  &.scroll-sw {
    animation-name: scroll-down-left;
  }

  &.scroll-ne {
    animation-name: scroll-up-right;
  }

  &.scroll-nw {
    animation-name: scroll-up-left;
  }

  &.paused {
    animation-play-state: paused;
  }
}

@keyframes scroll-down-right {
  to {
    transform: translateX(120px) translateY(128px);
  }
}

@keyframes scroll-down-left {
  to {
    transform: translateX(-120px) translateY(128px);
  }
}

@keyframes scroll-up-right {
  to {
    transform: translateX(120px) translateY(-128px);
  }
}

@keyframes scroll-up-left {
  to {
    transform: translateX(-120px) translateY(-128px);
  }
}
