.buttons-pane {
  display: flex;
  flex-direction: column;
}

@media (max-width: 720px) {
  .buttons-pane {
    flex-direction: row;
  }
}
