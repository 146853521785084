@import "window";

.content {
  background-color: rgba(0, 0, 0, 0.3);
  max-width: 900px;
  min-width: 600px;
  padding: 32px;
  border-radius: 4px;
  margin: 32px auto;
  z-index: 1;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0px;
}

@media (max-width: 720px) {
  .content {
    flex-direction: column;
    padding: 16px;
    min-width: auto;
    margin: 0;
  }
}
