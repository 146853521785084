.nav-button {
  line-height: 1;
  display: flex;
  justify-content: center;
  position: relative;

  &.displaying {
    color: #f0f0b8;
  }

  &.disabled {
    color: #c0c0c0;
  }

  &.selected {
    .hand {
      background-image: url("../images/hand-2x.png");
      width: 32px;
      height: 32px;
      position: absolute;
      left: 0;

      animation-name: bob-2x;
      animation-duration: 0.5s;
      animation-timing-function: ease-out;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }
}

@media (max-width: 500px) {
  .nav-button {
    &:hover {
      .hand {
        background-image: url("../images/hand.png");
        width: 16px;
        height: 16px;

        animation-name: bob;
      }
    }
  }
}

@keyframes bob {
  from {
    transform: translateX(-16px) translateY(4px);
  }
  to {
    transform: translateX(-24px) translateY(4px);
  }
}

@keyframes bob-2x {
  from {
    transform: translateX(-32px) translateY(8px);
  }
  to {
    transform: translateX(-48px) translateY(8px);
  }
}
