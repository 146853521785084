html {
  height: 100%;
}

body {
  margin: 0;
}

.root {
  width: 100%;
  display: flex;
  justify-content: center;
}
