.window {
  border: 1px solid transparent;

  background-color: black;
  border-image-source: url("../images/border-2x.png");
  border-image-slice: 12;
  border-image-width: 12px;
  border-image-outset: 6px;
  border-image-repeat: round;
  padding: 12px;
  margin: 12px;

  color: white;
  font-family: "Reactor7";
  font-size: 32pt;

  p {
    margin: 16px;
    line-height: 1;
  }

  button.icon {
    font-family: sans-serif;
    font-size: 20pt;
  }
}

@media (max-width: 500px) {
  .window {
    font-size: 16pt;
    border-image-width: 6px;
    border-image-outset: 3px;
    padding: 6px;
    margin: 6px;

    p {
      margin: 8px;
    }

    button.icon {
      font-size: 10pt;
    }
  }
}
