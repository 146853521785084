.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media (max-width: 720px) {
  .action-buttons {
    flex-direction: column;
  }
}
